<template>
  <v-card :loading="submitting">
    <v-card-title>
      Password Reset
    </v-card-title>
    <v-form
      v-if="!success"
      ref="form"
      class="passwordReset"
      @submit.prevent="passwordReset"
    >
      <v-card-text>
        <v-alert
          v-for="error in errorMessages"
          :key="error"
          type="error"
        >
          {{ error }}
        </v-alert>
        <p>Enter your email address to reset your password.</p>
        <v-text-field
          v-model="email"
          :disabled="submitting"
          :rules="emailRules"
          required
          type="email"
          label="Email"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="submitting"
          type="submit"
        >
          Request Reset
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-card-text v-else>
      Password reset email has been sent.
    </v-card-text>
  </v-card>
</template>

<script>
import {allianceBaseURL} from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import { emailRules } from '@/utils/rules'

export default {
  data() {
    return {
      email : '',
      submitting: false,
      success: null,
      errorMessages: null,
      emailRules: emailRules,
    }
  },
  methods: {
    passwordReset: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = null
      const data = {
        email: this.email,
      }
      return this.$http.post(allianceBaseURL + '/auth/password/reset/', data)
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.success = true
          this.email = ''
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    }
  },
}
</script>
